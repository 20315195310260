import React, {useContext} from 'react';
import BarberList from './BarberList';
import LanguageContext from "./LanguageContext";

const Shops = () => {
    const { language } = useContext(LanguageContext);

    const translations = {
        en: {
            findABarber: "Find a Barber"
        },
        tr: {
            findABarber: "Berber Bul"
        },
        ar: {
            findABarber: "ابحث عن حلاق"
        },
        de: {
            findABarber: "Finde einen Friseur"
        }
    };

    const t = translations[language];

    return (
        <div className="container mx-auto px-4 py-8">
            <h1 className="text-3xl font-bold mb-6">{t.findABarber}</h1>
            <BarberList />
        </div>
    );
};

export default Shops;