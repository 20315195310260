import React, {useState, useEffect, useContext} from 'react';
import {auth, storage, signInWithGoogle, db} from '../firebase';
import { createUserWithEmailAndPassword, signInWithEmailAndPassword, onAuthStateChanged, updateProfile } from 'firebase/auth';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { FaGoogle, FaEnvelope, FaLock, FaUser, FaImage } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import {doc, setDoc} from "firebase/firestore";
import LanguageContext from "./LanguageContext";

const Auth = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [name, setName] = useState('');
    const [profileImage, setProfileImage] = useState(null);
    const [error, setError] = useState('');
    const [isSignUp, setIsSignUp] = useState(false);
    const navigate = useNavigate();
    const { language } = useContext(LanguageContext);

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (user) {
                console.log("User is signed in:", user);
                navigate('/create-shop');
            } else {
                console.log("No user is signed in.");
            }
        });

        return () => unsubscribe();
    }, [navigate]);

    const handleAuth = async (e) => {
        e.preventDefault();
        setError('');
        try {
            if (isSignUp) {
                console.log("Attempting to create new user");
                const userCredential = await createUserWithEmailAndPassword(auth, email, password);
                const user = userCredential.user;

                // Upload profile image if provided
                let photoURL = '';
                if (profileImage) {
                    const imageRef = ref(storage, `profile_images/${user.uid}`);
                    await uploadBytes(imageRef, profileImage);
                    photoURL = await getDownloadURL(imageRef);
                }

                // Update user profile
                await updateProfile(user, {
                    displayName: name,
                    photoURL: photoURL
                });

                console.log("User created and profile updated successfully");
            } else {
                console.log("Attempting to sign in user");
                await signInWithEmailAndPassword(auth, email, password);
                console.log("User signed in successfully");
            }
        } catch (error) {
            console.error("Authentication error:", error);
            setError(error.message);
        }
    };

    const handleGoogleSignIn = async () => {
        setError('');
        try {
            await signInWithGoogle();
        } catch (error) {
            console.error("Error during Google Sign-In:", error);
            setError(error.message);
        }
    };

    const handleImageChange = (e) => {
        if (e.target.files[0]) {
            setProfileImage(e.target.files[0]);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            let userCredential;
            if (isSignUp) {
                userCredential = await createUserWithEmailAndPassword(auth, email, password);
                const user = userCredential.user;

                // Set up trial period
                const trialEndDate = new Date();
                trialEndDate.setDate(trialEndDate.getDate() + 14); // 14-day trial

                await setDoc(doc(db, 'users', user.uid), {
                    email: user.email,
                    trialEndDate: trialEndDate,
                    isSubscribed: false
                });
            } else {
                userCredential = await signInWithEmailAndPassword(auth, email, password);
            }
            navigate('/');
        } catch (error) {
            console.error('Authentication error:', error);
            // Handle error (show message to user)
        }
    };

    const translations = {
        en: {
            createAccount: "Create your account",
            signIn: "Sign in to your account",
            emailAddress: "Email address",
            emailPlaceholder: "you@example.com",
            password: "Password",
            passwordPlaceholder: "••••••••",
            fullName: "Full Name",
            fullNamePlaceholder: "John Doe",
            profileImage: "Profile Image",
            change: "Change",
            signUp: "Sign Up",
            signInButton: "Sign In",
            orContinueWith: "Or continue with",
            signInWithGoogle: "Sign in with Google",
            alreadyHaveAccount: "Already have an account?",
            dontHaveAccount: "Don't have an account?"
        },
        tr: {
            createAccount: "Hesabınızı oluşturun",
            signIn: "Hesabınıza giriş yapın",
            emailAddress: "E-posta adresi",
            emailPlaceholder: "siz@ornek.com",
            password: "Şifre",
            passwordPlaceholder: "••••••••",
            fullName: "Tam Ad",
            fullNamePlaceholder: "Ahmet Yılmaz",
            profileImage: "Profil Resmi",
            change: "Değiştir",
            signUp: "Kayıt Ol",
            signInButton: "Giriş Yap",
            orContinueWith: "Veya şununla devam edin",
            signInWithGoogle: "Google ile giriş yap",
            alreadyHaveAccount: "Zaten bir hesabınız var mı?",
            dontHaveAccount: "Hesabınız yok mu?"
        },
        ar: {
            createAccount: "إنشاء حسابك",
            signIn: "تسجيل الدخول إلى حسابك",
            emailAddress: "عنوان البريد الإلكتروني",
            emailPlaceholder: "أنت@مثال.com",
            password: "كلمة المرور",
            passwordPlaceholder: "••••••••",
            fullName: "الاسم الكامل",
            fullNamePlaceholder: "محمد علي",
            profileImage: "صورة الملف الشخصي",
            change: "تغيير",
            signUp: "التسجيل",
            signInButton: "تسجيل الدخول",
            orContinueWith: "أو تابع باستخدام",
            signInWithGoogle: "تسجيل الدخول باستخدام Google",
            alreadyHaveAccount: "هل لديك حساب بالفعل؟",
            dontHaveAccount: "ليس لديك حساب؟"
        },
        de: {
            createAccount: "Erstellen Sie Ihr Konto",
            signIn: "Melden Sie sich bei Ihrem Konto an",
            emailAddress: "E-Mail-Adresse",
            emailPlaceholder: "sie@beispiel.de",
            password: "Passwort",
            passwordPlaceholder: "••••••••",
            fullName: "Vollständiger Name",
            fullNamePlaceholder: "Max Mustermann",
            profileImage: "Profilbild",
            change: "Ändern",
            signUp: "Registrieren",
            signInButton: "Anmelden",
            orContinueWith: "Oder fortfahren mit",
            signInWithGoogle: "Mit Google anmelden",
            alreadyHaveAccount: "Haben Sie bereits ein Konto?",
            dontHaveAccount: "Sie haben noch kein Konto?"
        }
    };

    const t = translations[language];

    return (
        <div className="min-h-screen bg-gray-100 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
            <div className="sm:mx-auto sm:w-full sm:max-w-md">
                <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
                    {isSignUp ? t.createAccount : t.signIn}
                </h2>
            </div>

            <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
                <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
                    <form className="space-y-6" onSubmit={handleSubmit}>
                        <div>
                            <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                                {t.emailAddress}
                            </label>
                            <div className="mt-1 relative rounded-md shadow-sm">
                                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                    <FaEnvelope className="h-5 w-5 text-gray-400" aria-hidden="true"/>
                                </div>
                                <input
                                    id="email"
                                    name="email"
                                    type="email"
                                    autoComplete="email"
                                    required
                                    className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-10 sm:text-sm border-gray-300 rounded-md"
                                    placeholder={t.emailPlaceholder}
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                            </div>
                        </div>

                        <div>
                            <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                                {t.password}
                            </label>
                            <div className="mt-1 relative rounded-md shadow-sm">
                                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                    <FaLock className="h-5 w-5 text-gray-400" aria-hidden="true"/>
                                </div>
                                <input
                                    id="password"
                                    name="password"
                                    type="password"
                                    autoComplete="current-password"
                                    required
                                    className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-10 sm:text-sm border-gray-300 rounded-md"
                                    placeholder={t.passwordPlaceholder}
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                />
                            </div>
                        </div>

                        {isSignUp && (
                            <>
                                <div>
                                    <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                                        {t.fullName}
                                    </label>
                                    <div className="mt-1 relative rounded-md shadow-sm">
                                        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                            <FaUser className="h-5 w-5 text-gray-400" aria-hidden="true"/>
                                        </div>
                                        <input
                                            id="name"
                                            name="name"
                                            type="text"
                                            autoComplete="name"
                                            required
                                            className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-10 sm:text-sm border-gray-300 rounded-md"
                                            placeholder={t.fullNamePlaceholder}
                                            value={name}
                                            onChange={(e) => setName(e.target.value)}
                                        />
                                    </div>
                                </div>

                                <div>
                                    <label htmlFor="profile-image" className="block text-sm font-medium text-gray-700">
                                        {t.profileImage}
                                    </label>
                                    <div className="mt-1 flex items-center">
                                        <span className="inline-block h-12 w-12 rounded-full overflow-hidden bg-gray-100">
                                            {profileImage ? (
                                                <img src={URL.createObjectURL(profileImage)} alt="Profile preview" className="h-full w-full object-cover"/>
                                            ) : (
                                                <FaUser className="h-full w-full text-gray-300" aria-hidden="true"/>
                                            )}
                                        </span>
                                        <label
                                            htmlFor="profile-image-upload"
                                            className="ml-5 bg-white py-2 px-3 border border-gray-300 rounded-md shadow-sm text-sm leading-4 font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                        >
                                            <FaImage className="h-5 w-5 text-gray-400 mr-2 inline"/>
                                            {t.change}
                                        </label>
                                        <input
                                            id="profile-image-upload"
                                            name="profile-image-upload"
                                            type="file"
                                            className="sr-only"
                                            accept="image/*"
                                            onChange={handleImageChange}
                                        />
                                    </div>
                                </div>
                            </>
                        )}

                        {error && <p className="text-red-500 text-sm">{error}</p>}

                        <div>
                            <button
                                type="submit"
                                className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                            >
                                {isSignUp ? t.signUp : t.signInButton}
                            </button>
                        </div>
                    </form>

                    <div className="mt-6">
                        <div className="relative">
                            <div className="absolute inset-0 flex items-center">
                                <div className="w-full border-t border-gray-300"/>
                            </div>
                            <div className="relative flex justify-center text-sm">
                                <span className="px-2 bg-white text-gray-500">{t.orContinueWith}</span>
                            </div>
                        </div>

                        <div className="mt-6">
                            <button
                                onClick={handleGoogleSignIn}
                                className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                            >
                                <FaGoogle className="h-5 w-5 mr-2"/>
                                {t.signInWithGoogle}
                            </button>
                        </div>
                    </div>

                    <p className="mt-4 text-center">
                        {isSignUp ? t.alreadyHaveAccount : t.dontHaveAccount}
                        <button
                            type="button"
                            onClick={() => setIsSignUp(!isSignUp)}
                            className="font-medium text-indigo-600 hover:text-indigo-500"
                        >
                            {isSignUp ? t.signInButton : t.signUp}
                        </button>
                    </p>
                </div>
            </div>
        </div>
    );
};

export default Auth;