import React, {useState, useEffect, useContext} from 'react';
import {Link} from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { db } from '../firebase';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import LanguageContext from "./LanguageContext";

const ShopLandingPage = () => {
    const { uniqueUrl } = useParams();
    const [shop, setShop] = useState(null);
    const [loading, setLoading] = useState(true);
    const { language } = useContext(LanguageContext);

    useEffect(() => {
        const fetchShopData = async () => {
            try {
                const shopsRef = collection(db, 'barberShops');
                const q = query(shopsRef, where("uniqueUrl", "==", uniqueUrl));
                const querySnapshot = await getDocs(q);

                if (!querySnapshot.empty) {
                    const shopDoc = querySnapshot.docs[0];
                    const shopData = shopDoc.data();
                    // Ensure imageUrls is an array and remove any duplicate URLs
                    const uniqueImageUrls = Array.from(new Set(shopData.imageUrls || []));
                    setShop({ id: shopDoc.id, ...shopData, imageUrls: uniqueImageUrls });
                }
            } catch (error) {
                console.error('Error fetching shop data:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchShopData();
    }, [uniqueUrl]);

    if (loading) {
        return <div className="text-center py-4">Loading...</div>;
    }

    if (!shop) {
        return <div className="text-center py-4">Shop not found.</div>;
    }

    const sliderSettings = {
        dots: true,
        infinite: shop.imageUrls.length > 1,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: shop.imageUrls.length > 1,
        autoplaySpeed: 3000,
    };

    const translations = {
        en: {
            loading: "Loading...",
            shopNotFound: "Shop not found.",
            noImagesAvailable: "No images available",
            aboutUs: "About Us",
            address: "Address:",
            phone: "Phone:",
            email: "Email:",
            ourServices: "Our Services",
            availability: "Availability",
            closed: "Closed",
            bookNow: "Book Now"
        },
        tr: {
            loading: "Yükleniyor...",
            shopNotFound: "Dükkan bulunamadı.",
            noImagesAvailable: "Resim mevcut değil",
            aboutUs: "Hakkımızda",
            address: "Adres:",
            phone: "Telefon:",
            email: "E-posta:",
            ourServices: "Hizmetlerimiz",
            availability: "Müsaitlik",
            closed: "Kapalı",
            bookNow: "Şimdi Rezervasyon Yap"
        },
        ar: {
            loading: "جاري التحميل...",
            shopNotFound: "لم يتم العثور على المحل.",
            noImagesAvailable: "لا تتوفر صور",
            aboutUs: "معلومات عنا",
            address: "العنوان:",
            phone: "الهاتف:",
            email: "البريد الإلكتروني:",
            ourServices: "خدماتنا",
            availability: "الأوقات المتاحة",
            closed: "مغلق",
            bookNow: "احجز الآن"
        },
        de: {
            loading: "Wird geladen...",
            shopNotFound: "Geschäft nicht gefunden.",
            noImagesAvailable: "Keine Bilder verfügbar",
            aboutUs: "Über uns",
            address: "Adresse:",
            phone: "Telefon:",
            email: "E-Mail:",
            ourServices: "Unsere Dienstleistungen",
            availability: "Verfügbarkeit",
            closed: "Geschlossen",
            bookNow: "Jetzt buchen"
        }
    };

    const t = translations[language];

    return (
        <div className="container mx-auto px-4 py-8">
            <h1 className="text-4xl font-bold mb-6">{shop.name}</h1>

            {shop.imageUrls.length > 0 ? (
                shop.imageUrls.length === 1 ? (
                    <div className="mb-8">
                        <img src={shop.imageUrls[0]} alt={`${shop.name}`} className="w-full h-96 object-cover"/>
                    </div>
                ) : (
                    <Slider {...sliderSettings} className="mb-8">
                        {shop.imageUrls.map((url, index) => (
                            <div key={index}>
                                <img src={url} alt={`${shop.name} - Image ${index + 1}`}
                                     className="w-full h-96 object-cover"/>
                            </div>
                        ))}
                    </Slider>
                )
            ) : (
                <div className="mb-8 text-center">{t.noImagesAvailable}</div>
            )}

            <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                <div>
                    <h2 className="text-2xl font-semibold mb-4">{t.aboutUs}</h2>
                    <p className="mb-4">{shop.biography}</p>
                    <p><strong>{t.address}</strong> {shop.address}</p>
                    <p><strong>{t.phone}</strong> {shop.phoneNumber}</p>
                    <p><strong>{t.email}</strong> {shop.email}</p>
                </div>

                <div>
                    <h2 className="text-2xl font-semibold mb-4">{t.ourServices}</h2>
                    <ul className="space-y-2">
                        {shop.services.map((service, index) => (
                            <li key={index} className="flex justify-between">
                                <span>{service.name}</span>
                                <span>€{service.price}</span>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>

            <div className="mt-8">
                <h2 className="text-2xl font-semibold mb-4">{t.availability}</h2>
                <ul className="space-y-2">
                    {Object.entries(shop.availability).map(([day, hours]) => (
                        <li key={day}>
                            <strong>{day}:</strong> {hours ? `${hours.open} - ${hours.close}` : t.closed}
                        </li>
                    ))}
                </ul>
            </div>

            <div className="mt-8 text-center">
                <Link to={`/book/${shop.id}`} className="btn btn-primary btn-lg">
                    {t.bookNow}
                </Link>
            </div>
        </div>
    );
};

export default ShopLandingPage;