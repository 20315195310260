import React, {useState, useEffect, useContext, useMemo} from 'react';
import { useParams } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../firebase';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Autoplay } from 'swiper/modules';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import LanguageContext from "./LanguageContext";

const BookNow = () => {
    const { language } = useContext(LanguageContext);
    const { shopId } = useParams();
    const [shop, setShop] = useState(null);
    const [loading, setLoading] = useState(true);
    const [selectedDate, setSelectedDate] = useState('');
    const [selectedServices, setSelectedServices] = useState([]);
    const [customService, setCustomService] = useState('');
    const [selectedTime, setSelectedTime] = useState('');
    const [userName, setUserName] = useState('');
    const [userEmail, setUserEmail] = useState('');
    const [userPhone, setUserPhone] = useState('');
    const [bookingStatus, setBookingStatus] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const fetchShopData = async () => {
            try {
                const shopDoc = await getDoc(doc(db, 'barberShops', shopId));
                if (shopDoc.exists()) {
                    setShop({ id: shopDoc.id, ...shopDoc.data() });
                }
            } catch (error) {
                console.error('Error fetching shop data:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchShopData();
    }, [shopId]);

    // const handleServiceChange = (e) => {
    //     const { value, checked } = e.target;
    //     if (checked) {
    //         setSelectedServices([...selectedServices, value]);
    //     } else {
    //         setSelectedServices(selectedServices.filter(service => service !== value));
    //     }
    // };

    const handleServiceChange = (e) => {
        const selectedService = shop.services.find(service => service.name === e.target.value);
        if (selectedService && !selectedServices.some(s => s.name === selectedService.name)) {
            setSelectedServices([...selectedServices, selectedService]);
        }
    };

    const removeService = (serviceName, e) => {
        e.preventDefault();
        setSelectedServices(selectedServices.filter(service => service.name !== serviceName));
    };

    const totalPrice = useMemo(() => {
        return selectedServices.reduce((sum, service) => sum + parseFloat(service.price), 0).toFixed(2);
    }, [selectedServices]);

    const handleBooking = async (e) => {
        e.preventDefault();
        if (!userName || !userEmail || !selectedDate || selectedServices.length === 0 || !selectedTime) {
            setBookingStatus(t.fillAllFields);
            return;
        }

        setIsLoading(true);
        setBookingStatus('');

        const bookingData = {
            shopId,
            shopEmail: shop.email, // Make sure this is correctly set
            userName,
            userEmail,
            userPhone,
            selectedDate,
            selectedServices,
            customService,
            selectedTime
        };

        console.log('Sending booking data:', bookingData); // Log the data being sent

        try {
            const response = await fetch('https://us-central1-easycut-2d3fa.cloudfunctions.net/createBooking', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(bookingData),
            });

            const responseData = await response.json();

            if (response.ok) {
                setBookingStatus(`${t.bookingSuccessful} Booking ID: ${responseData.bookingId}`);
                // Reset form fields
                setUserName('');
                setUserEmail('');
                setUserPhone('');
                setSelectedDate('');
                setSelectedServices([]);
                setCustomService('');
                setSelectedTime('');
            } else {
                console.error('Booking failed:', responseData.error);
                setBookingStatus(`${t.bookingFailed} ${responseData.error || ''}`);
            }
        } catch (error) {
            console.error('Error booking appointment:', error);
            setBookingStatus(`${t.errorOccurred} ${error.message}`);
        } finally {
            setIsLoading(false);
        }
    };

    if (loading) {
        return <div className="flex justify-center items-center h-screen">Loading...</div>;
    }

    if (!shop) {
        return <div className="text-center py-4">Shop not found.</div>;
    }

    const availableTimeSlots = ['09:00', '10:00', '11:00', '12:00', '13:00', '14:00', '15:00', '16:00', '17:00'];

    const translations = {
        en: {
            pickMoreServices: "Pick more services",
            removeService: "Remove",
            loading: "Loading...",
            shopNotFound: "Shop not found.",
            total: "Total",
            aboutUs: "About Us",
            address: "Address:",
            phone: "Phone:",
            email: "Email:",
            ourServices: "Our Services",
            bookYourAppointment: "Book Your Appointment",
            name: "Name (required)",
            emailRequired: "Email (required)",
            phoneOptional: "Phone (optional)",
            selectDate: "Select a Date",
            selectService: "Select a Service",
            chooseService: "Choose a service",
            selectTime: "Select a Time",
            chooseTimeSlot: "Choose a time slot",
            bookAppointment: "Book Appointment",
            availability: "Availability",
            closed: "Closed",
            fillAllFields: "Please fill in all required fields and select at least one service.",
            bookingSuccessful: "Booking successful! Confirmation emails have been sent.",
            bookingFailed: "Booking failed. Please try again.",
            errorOccurred: "An error occurred. Please try again."
        },
        tr: {
            loading: "Yükleniyor...",
            pickMoreServices: "Daha fazla hizmet seç",
            removeService: "Kaldır",
            shopNotFound: "Dükkan bulunamadı.",
            aboutUs: "Hakkımızda",
            address: "Adres:",
            phone: "Telefon:",
            total: "Toplam",
            email: "E-posta:",
            ourServices: "Hizmetlerimiz",
            bookYourAppointment: "Randevunuzu Alın",
            name: "İsim (gerekli)",
            emailRequired: "E-posta (gerekli)",
            phoneOptional: "Telefon (isteğe bağlı)",
            selectDate: "Bir Tarih Seçin",
            selectService: "Bir Hizmet Seçin",
            chooseService: "Bir hizmet seçin",
            selectTime: "Bir Saat Seçin",
            chooseTimeSlot: "Bir zaman dilimi seçin",
            bookAppointment: "Randevu Al",
            availability: "Müsaitlik",
            closed: "Kapalı",
            fillAllFields: "Lütfen tüm gerekli alanları doldurun ve en az bir hizmet seçin.",
            bookingSuccessful: "Rezervasyon başarılı! Onay e-postaları gönderildi.",
            bookingFailed: "Rezervasyon başarısız oldu. Lütfen tekrar deneyin.",
            errorOccurred: "Bir hata oluştu. Lütfen tekrar deneyin."
        },
        ar: {
            loading: "جاري التحميل...",
            shopNotFound: "لم يتم العثور على المحل.",
            pickMoreServices: "اختر المزيد من الخدمات",
            removeService: "إزالة",
            aboutUs: "معلومات عنا",
            total: "المجموع",
            address: "العنوان:",
            phone: "الهاتف:",
            email: "البريد الإلكتروني:",
            ourServices: "خدماتنا",
            bookYourAppointment: "احجز موعدك",
            name: "الاسم (مطلوب)",
            emailRequired: "البريد الإلكتروني (مطلوب)",
            phoneOptional: "الهاتف (اختياري)",
            selectDate: "اختر تاريخًا",
            selectService: "اختر خدمة",
            chooseService: "اختر خدمة",
            selectTime: "اختر وقتًا",
            chooseTimeSlot: "اختر فترة زمنية",
            bookAppointment: "احجز الموعد",
            availability: "الأوقات المتاحة",
            closed: "مغلق",
            fillAllFields: "يرجى ملء جميع الحقول المطلوبة واختيار خدمة واحدة على الأقل.",
            bookingSuccessful: "تم الحجز بنجاح! تم إرسال رسائل التأكيد عبر البريد الإلكتروني.",
            bookingFailed: "فشل الحجز. يرجى المحاولة مرة أخرى.",
            errorOccurred: "حدث خطأ. يرجى المحاولة مرة أخرى."
        },
        de: {
            loading: "Wird geladen...",
            shopNotFound: "Geschäft nicht gefunden.",
            aboutUs: "Über uns",
            address: "Adresse:",
            phone: "Telefon:",
            email: "E-Mail:",
            ourServices: "Unsere Dienstleistungen",
            pickMoreServices: "Wählen Sie weitere Dienstleistungen",
            removeService: "Entfernen",
            bookYourAppointment: "Buchen Sie Ihren Termin",
            name: "Name (erforderlich)",
            emailRequired: "E-Mail (erforderlich)",
            phoneOptional: "Telefon (optional)",
            selectDate: "Wählen Sie ein Datum",
            total: "Gesamt",
            selectService: "Wählen Sie einen Service",
            chooseService: "Wählen Sie einen Service",
            selectTime: "Wählen Sie eine Uhrzeit",
            chooseTimeSlot: "Wählen Sie einen Zeitslot",
            bookAppointment: "Termin buchen",
            availability: "Verfügbarkeit",
            closed: "Geschlossen",
            fillAllFields: "Bitte füllen Sie alle erforderlichen Felder aus und wählen Sie mindestens einen Service.",
            bookingSuccessful: "Buchung erfolgreich! Bestätigungs-E-Mails wurden gesendet.",
            bookingFailed: "Buchung fehlgeschlagen. Bitte versuchen Sie es erneut.",
            errorOccurred: "Ein Fehler ist aufgetreten. Bitte versuchen Sie es erneut."
        }
    };

    const t = translations[language];

    return (
        <div className="container mx-auto px-4 py-8">
            <h1 className="text-4xl font-bold mb-6 text-center">{shop.name}</h1>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                {/* Left column with shop details */}
                <div>
                    <Swiper
                        modules={[Navigation, Pagination, Autoplay]}
                        spaceBetween={30}
                        slidesPerView={1}
                        navigation
                        pagination={{clickable: true}}
                        autoplay={{delay: 3000}}
                        className="mb-8 rounded-lg overflow-hidden"
                    >
                        {shop.imageUrls.map((url, index) => (
                            <SwiperSlide key={index}>
                                <img src={url} alt={`${shop.name} - Image ${index + 1}`}
                                     className="w-full h-64 object-cover"/>
                            </SwiperSlide>
                        ))}
                    </Swiper>

                    <div className="bg-white shadow-md rounded-lg p-6 mb-8">
                        <h2 className="text-2xl font-semibold mb-4">{t.aboutUs}</h2>
                        <p className="mb-4">{shop.biography}</p>
                        <p><strong>{t.address}</strong> {shop.address}</p>
                        <a href={`tel:${shop.phoneNumber}`} className="text-blue-600 hover:underline">
                            {shop.phoneNumber}
                        </a>
                        <br/>
                        <a href={`mailto:${shop.email}`} className="text-blue-600 hover:underline">
                            {shop.email}
                        </a>
                    </div>

                    {/*<div>*/}
                    {/*    <label className="block text-sm font-medium text-gray-700 mb-1">{t.selectServices}</label>*/}
                    {/*    <div className="max-h-40 overflow-y-auto">*/}
                    {/*        {shop.services.map((service, index) => (*/}
                    {/*            <div key={index} className="flex items-center mb-2">*/}
                    {/*                <input*/}
                    {/*                    type="checkbox"*/}
                    {/*                    id={`service-${index}`}*/}
                    {/*                    value={service.name}*/}
                    {/*                    checked={selectedServices.includes(service.name)}*/}
                    {/*                    onChange={handleServiceChange}*/}
                    {/*                    className="mr-2"*/}
                    {/*                />*/}
                    {/*                <label htmlFor={`service-${index}`} className="flex-grow">*/}
                    {/*                    {service.name} - €{service.price}*/}
                    {/*                </label>*/}
                    {/*            </div>*/}
                    {/*        ))}*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                </div>

                {/* Right column with booking form */}
                <div>
                    <div className="bg-white shadow-md rounded-lg p-6 mb-8">
                        <h2 className="text-2xl font-semibold mb-4">{t.bookYourAppointment}</h2>
                        <form onSubmit={handleBooking} className="space-y-4">
                            <div>
                                <label htmlFor="name"
                                       className="block text-sm font-medium text-gray-700 mb-1">{t.name}</label>
                                <input
                                    type="text"
                                    id="name"
                                    value={userName}
                                    onChange={(e) => setUserName(e.target.value)}
                                    className="w-full p-2 border rounded-md"
                                    required
                                />
                            </div>

                            <div>
                                <label htmlFor="email"
                                       className="block text-sm font-medium text-gray-700 mb-1">{t.emailRequired}</label>
                                <input
                                    type="email"
                                    id="email"
                                    value={userEmail}
                                    onChange={(e) => setUserEmail(e.target.value)}
                                    className="w-full p-2 border rounded-md"
                                    required
                                />
                            </div>

                            <div>
                                <label htmlFor="phone"
                                       className="block text-sm font-medium text-gray-700 mb-1">{t.phoneOptional}</label>
                                <input
                                    type="tel"
                                    id="phone"
                                    value={userPhone}
                                    onChange={(e) => setUserPhone(e.target.value)}
                                    className="w-full p-2 border rounded-md"
                                />
                            </div>

                            <div>
                                <label htmlFor="date"
                                       className="block text-sm font-medium text-gray-700 mb-1">{t.selectDate}</label>
                                <input
                                    type="date"
                                    id="date"
                                    value={selectedDate}
                                    onChange={(e) => setSelectedDate(e.target.value)}
                                    className="w-full p-2 border rounded-md"
                                    required
                                />
                            </div>

                            <div>
                                <label htmlFor="service" className="block text-sm font-medium text-gray-700 mb-1">
                                    {selectedServices.length > 0 ? t.pickMoreServices : t.selectService}
                                </label>
                                <select
                                    id="service"
                                    value=""
                                    onChange={handleServiceChange}
                                    className="w-full p-2 border rounded-md"
                                >
                                    <option value="">{t.chooseService}</option>
                                    {shop.services.map((service, index) => (
                                        <option key={index} value={service.name}>
                                            {service.name} - €{service.price}
                                        </option>
                                    ))}
                                </select>
                            </div>

                            <div className="mt-4">
                                {selectedServices.map((service, index) => (
                                    <div key={index}
                                         className="flex items-center justify-between bg-gray-100 p-2 rounded mb-2">
                                        <span>{service.name} - €{service.price}</span>
                                        <button
                                            onClick={(e) => removeService(service.name, e)}
                                            className="text-red-600 hover:text-red-800"
                                            type="button" // Explicitly set the button type to "button"
                                        >
                                            {t.removeService}
                                        </button>
                                    </div>
                                ))}
                            </div>

                            <div>
                                <label htmlFor="time"
                                       className="block text-sm font-medium text-gray-700 mb-1">{t.selectTime}</label>
                                <select
                                    id="time"
                                    value={selectedTime}
                                    onChange={(e) => setSelectedTime(e.target.value)}
                                    className="w-full p-2 border rounded-md"
                                    required
                                >
                                    <option value="">{t.chooseTimeSlot}</option>
                                    {availableTimeSlots.map((time, index) => (
                                        <option key={index} value={time}>{time}</option>
                                    ))}
                                </select>
                            </div>

                            {selectedServices.length > 0 && (
                                <div className="mt-4 p-4 bg-blue-50 rounded-lg shadow-sm">
                                    <div className="flex justify-between items-center">
                                        <span className="text-lg font-semibold text-blue-800">{t.total}</span>
                                        <span className="text-2xl font-bold text-blue-800">€{totalPrice}</span>
                                    </div>
                                </div>
                            )}

                            <button
                                type="submit"
                                className="w-full bg-blue-600 text-white py-2 px-4 rounded-md hover:bg-blue-700 transition duration-300 flex justify-center items-center"
                                disabled={isLoading}
                            >
                                {isLoading ? (
                                    <span className="loading loading-bars loading-lg"></span>
                                ) : (
                                    t.bookAppointment
                                )}
                            </button>
                        </form>
                        {bookingStatus && <p className="mt-4 text-center font-semibold">{bookingStatus}</p>}
                    </div>

                    <div className="bg-white shadow-md rounded-lg p-6">
                        <h2 className="text-2xl font-semibold mb-4">{t.availability}</h2>
                        <ul className="space-y-2">
                            {Object.entries(shop.availability).map(([day, hours]) => (
                                <li key={day}>
                                    <strong>{day}:</strong> {hours ? `${hours.open} - ${hours.close}` : t.closed}
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BookNow;