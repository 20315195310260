import React, {useState, useEffect, useContext} from 'react';
import {collection, getDocs} from 'firebase/firestore';
import {db} from '../firebase';
import {Link} from 'react-router-dom';
import LanguageContext from "./LanguageContext";

const BarberList = () => {
    const [barbers, setBarbers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [currentImageIndices, setCurrentImageIndices] = useState({});
    const {language} = useContext(LanguageContext);

    useEffect(() => {
        const fetchBarbers = async () => {
            try {
                const barbersCollection = collection(db, 'barberShops');
                const barberSnapshot = await getDocs(barbersCollection);
                const barberList = barberSnapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data()
                }));

                setBarbers(barberList);
                const initialIndices = {};
                barberList.forEach(barber => {
                    initialIndices[barber.id] = 0;
                });
                setCurrentImageIndices(initialIndices);
                setLoading(false);
            } catch (error) {
                console.error("Error fetching barbers: ", error);
                setError("Failed to fetch barbers. Please try again later.");
                setLoading(false);
            }
        };

        fetchBarbers();
    }, []);

    const toggleImage = (barberId, direction) => {
        setCurrentImageIndices(prevIndices => {
            const currentIndex = prevIndices[barberId];
            const imageCount = barbers.find(b => b.id === barberId).imageUrls.length;
            let newIndex;
            if (direction === 'next') {
                newIndex = (currentIndex + 1) % imageCount;
            } else {
                newIndex = (currentIndex - 1 + imageCount) % imageCount;
            }
            return {...prevIndices, [barberId]: newIndex};
        });
    };

    const handlePhoneClick = (phoneNumber) => {
        window.location.href = `tel:${phoneNumber}`;
    };

    const handleEmailClick = (email) => {
        window.location.href = `mailto:${email}`;
    };

    if (loading) {
        return <div className="text-center py-4">Loading...</div>;
    }

    if (error) {
        return <div className="text-center py-4 text-red-500">{error}</div>;
    }

    const formatAvailability = (availability) => {
        console.log("Formatting availability:", availability); // Keep this debug log

        if (!availability || typeof availability !== 'object') {
            return 'No availability information';
        }

        const formattedAvailability = Object.entries(availability)
            .filter(([_, hours]) => hours !== null && typeof hours === 'object' && hours.open && hours.close)
            .map(([day, hours]) => `${day}: ${hours.open} - ${hours.close}`);

        return formattedAvailability.length > 0
            ? formattedAvailability.join(', ')
            : 'No available hours set';
    };

    const translations = {
        en: {
            loading: "Loading...",
            error: "Failed to fetch barbers. Please try again later.",
            noImageAvailable: "No image available",
            prev: "Prev",
            next: "Next",
            address: "Address:",
            phone: "Phone:",
            email: "Email:",
            biography: "Biography:",
            noBiographyAvailable: "No biography available",
            createdAt: "Created At:",
            availability: "Availability:",
            noAvailabilityInfo: "No availability information",
            noAvailableHoursSet: "No available hours set",
            services: "Services:",
            bookNow: "Book Now"
        },
        tr: {
            loading: "Yükleniyor...",
            error: "Berberler getirilemedi. Lütfen daha sonra tekrar deneyin.",
            noImageAvailable: "Resim mevcut değil",
            prev: "Önceki",
            next: "Sonraki",
            address: "Adres:",
            phone: "Telefon:",
            email: "E-posta:",
            biography: "Biyografi:",
            noBiographyAvailable: "Biyografi mevcut değil",
            createdAt: "Oluşturulma Tarihi:",
            availability: "Müsaitlik:",
            noAvailabilityInfo: "Müsaitlik bilgisi yok",
            noAvailableHoursSet: "Müsait saat ayarlanmamış",
            services: "Hizmetler:",
            bookNow: "Şimdi Rezervasyon Yap"
        },
        ar: {
            loading: "جاري التحميل...",
            error: "فشل في جلب الحلاقين. يرجى المحاولة مرة أخرى لاحقًا.",
            noImageAvailable: "لا تتوفر صورة",
            prev: "السابق",
            next: "التالي",
            address: "العنوان:",
            phone: "الهاتف:",
            email: "البريد الإلكتروني:",
            biography: "السيرة الذاتية:",
            noBiographyAvailable: "لا تتوفر سيرة ذاتية",
            createdAt: "تم الإنشاء في:",
            availability: "التوفر:",
            noAvailabilityInfo: "لا توجد معلومات عن التوفر",
            noAvailableHoursSet: "لم يتم تعيين ساعات متاحة",
            services: "الخدمات:",
            bookNow: "احجز الآن"
        },
        de: {
            loading: "Wird geladen...",
            error: "Friseure konnten nicht abgerufen werden. Bitte versuchen Sie es später erneut.",
            noImageAvailable: "Kein Bild verfügbar",
            prev: "Zurück",
            next: "Weiter",
            address: "Adresse:",
            phone: "Telefon:",
            email: "E-Mail:",
            biography: "Biografie:",
            noBiographyAvailable: "Keine Biografie verfügbar",
            createdAt: "Erstellt am:",
            availability: "Verfügbarkeit:",
            noAvailabilityInfo: "Keine Verfügbarkeitsinformationen",
            noAvailableHoursSet: "Keine verfügbaren Stunden festgelegt",
            services: "Dienstleistungen:",
            bookNow: "Jetzt buchen"
        }
    };

    const t = translations[language];

    return (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 p-4">
            {barbers.map((barber) => (
                <div key={barber.id} className="card bg-base-100 shadow-xl overflow-hidden">
                    <div className="relative">
                        {barber.imageUrls && barber.imageUrls.length > 0 ? (
                            <>
                                <img
                                    src={barber.imageUrls[currentImageIndices[barber.id]]}
                                    alt={`${barber.name} shop`}
                                    className="w-full h-48 object-cover"
                                    onError={(e) => {
                                        console.error(`Failed to load image: ${barber.imageUrls[currentImageIndices[barber.id]]}`);
                                        e.target.src = '/path/to/placeholder-image.jpg';
                                    }}
                                />
                                {barber.imageUrls.length > 1 && (
                                    <div className="absolute inset-x-0 bottom-0 flex justify-between p-2">
                                        <button
                                            onClick={() => toggleImage(barber.id, 'prev')}
                                            className="bg-black bg-opacity-50 text-white px-2 py-1 rounded"
                                        >
                                            &#8592; {t.prev}
                                        </button>
                                        <button
                                            onClick={() => toggleImage(barber.id, 'next')}
                                            className="bg-black bg-opacity-50 text-white px-2 py-1 rounded"
                                        >
                                            {t.next} &#8594;
                                        </button>
                                    </div>
                                )}
                            </>
                        ) : (
                            <div className="w-full h-48 bg-gray-200 flex items-center justify-center">
                                <span className="text-gray-500">{t.noImageAvailable}</span>
                            </div>
                        )}
                    </div>
                    <div className="card-body">
                        <h2 className="card-title">{barber.name}</h2>
                        <p><strong>{t.address}</strong> {barber.address}</p>
                        <p>
                            <strong>{t.phone}</strong>
                            <button
                                onClick={() => handlePhoneClick(barber.phoneNumber)}
                                className="ml-2 text-blue-500 hover:underline"
                            >
                                {barber.phoneNumber}
                            </button>
                        </p>
                        <p>
                            <strong>{t.email}</strong>
                            <button
                                onClick={() => handleEmailClick(barber.email)}
                                className="ml-2 text-blue-500 hover:underline"
                            >
                                {barber.email}
                            </button>
                        </p>
                        <p><strong>{t.biography}</strong> {barber.biography || t.noBiographyAvailable}</p>
                        <p><strong>{t.createdAt}</strong> {barber.createdAt?.toDate().toLocaleString()}</p>
                        <p><strong>{t.availability}</strong> {formatAvailability(barber.availability)}</p>

                        {barber.services && barber.services.length > 0 && (
                            <div>
                                <h3 className="font-bold mt-2">{t.services}</h3>
                                <ul className="list-disc list-inside">
                                    {barber.services.map((service, index) => (
                                        <li key={index}>{service.name} - ${service.price}</li>
                                    ))}
                                </ul>
                            </div>
                        )}

                        <div className="card-actions justify-end mt-4">
                            <Link to={`/book/${barber.id}`} className="btn btn-primary">
                                {t.bookNow}
                            </Link>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default BarberList;