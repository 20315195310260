import React, {useContext} from 'react';
import {Link} from 'react-router-dom';
import './Home.css'
import TrialStatus from "./TrialStatus";
import auth from "./Auth";
import LanguageContext from "./LanguageContext";

const Home = () => {
    const {language} = useContext(LanguageContext);
    const translations = {
        en: {
            welcome: "Welcome to BarberBuddy",
            heroDescription: "Discover the art of the perfect haircut. Book your next appointment with the best barbers near you.",
            bookNow: "Book Now",
            ourFeatures: "Our Features",
            readyForNewLook: "Ready for your new look?",
            bookAndExperience: "Book your appointment now and experience the difference with BarberBuddy.",
            findBarber: "Find a Barber Now",
            easyBooking: "Easy Booking",
            easyBookingDesc: "Book your appointment with just a few clicks, anytime, anywhere.",
            bestBarbers: "Best Barbers",
            bestBarbersDesc: "We work with only the best barbers in your city.",
            customerReviews: "Customer Reviews",
            customerReviewsDesc: "Read real reviews from other customers before you book.",
            customStyles: "Custom Styles",
            customStylesDesc: "Choose from a variety of haircuts and beard grooming options.",
            flexibleAppointments: "Flexible Appointments",
            flexibleAppointmentsDesc: "Find the perfect appointment time that fits your busy schedule.",
            loyaltyProgram: "Loyalty Program",
            loyaltyProgramDesc: "Earn points with every booking and enjoy exclusive benefits.",
            ourSubscriptions: "Our Subscriptions",
            forBarbers: "For Barbers Only",
            premiumSubscription: "Premium Subscription",
            subscriptionDescription: "All benefits and technical support for 3 months",
            per3Months: "/3 months",
            subscriptionFeatures: [
                "Unlimited access to all features",
                "Priority customer support",
                "Exclusive training sessions",
                "Monthly performance reports"
            ],
            subscribeNow: "Subscribe Now",
            whatBarbersSay: "What Our Barbers Say",
            testimonial1Name: "John Doe",
            testimonial1Quote: "This app has revolutionized our appointment calendar! We save time daily and our customers love the easy online booking.",
            testimonial1Location: "Snip Snap Barbershop, Downtown",
            testimonial2Name: "Jane Smith",
            testimonial2Quote: "Thanks to the app, we can focus more on our customers. The management features are simply great!",
            testimonial2Location: "Hair Sharp Salon, Uptown",
            testimonial3Name: "Mike Johnson",
            testimonial3Quote: "Our revenue has increased by 30% since we started using the app. The reminder feature has drastically reduced no-shows!",
            testimonial3Location: "Beard Care Deluxe, Midtown",
            services: "Services",
            barbers: "Barbers",
            bookings: "Bookings",
            subscriptions: "Subscriptions",
            giftCards: "Gift Cards",
            company: "Company",
            aboutUs: "About Us",
            contact: "Contact",
            jobs: "Jobs",
            pressReleases: "Press Releases",
            legal: "Legal",
            termsOfUse: "Terms of Use",
            privacyPolicy: "Privacy Policy",
            cookiePolicy: "Cookie Policy"
        },
        tr: {
            welcome: "BarberBuddy'ye Hoş Geldiniz",
            heroDescription: "Mükemmel saç kesiminin sanatını keşfedin. Yakınınızdaki en iyi berberlerle bir sonraki randevunuzu alın.",
            bookNow: "Şimdi Rezervasyon Yap",
            ourFeatures: "Özelliklerimiz",
            readyForNewLook: "Yeni görünümünüz için hazır mısınız?",
            bookAndExperience: "Şimdi randevunuzu alın ve BarberBuddy ile farkı yaşayın.",
            findBarber: "Şimdi Berber Bul",
            easyBooking: "Kolay Rezervasyon",
            easyBookingDesc: "Randevunuzu birkaç tıklamayla, istediğiniz zaman, istediğiniz yerden alın.",
            bestBarbers: "En İyi Berberler",
            bestBarbersDesc: "Şehrinizdeki sadece en iyi berberlerle çalışıyoruz.",
            customerReviews: "Müşteri Yorumları",
            customerReviewsDesc: "Rezervasyon yapmadan önce diğer müşterilerin gerçek yorumlarını okuyun.",
            customStyles: "Özel Stiller",
            customStylesDesc: "Çeşitli saç kesimi ve sakal bakımı seçenekleri arasından seçim yapın.",
            flexibleAppointments: "Esnek Randevular",
            flexibleAppointmentsDesc: "Yoğun programınıza uyan mükemmel randevu zamanını bulun.",
            loyaltyProgram: "Sadakat Programı",
            loyaltyProgramDesc: "Her rezervasyonla puan kazanın ve özel avantajların tadını çıkarın.",
            ourSubscriptions: "Aboneliklerimiz",
            forBarbers: "Sadece Berberler İçin",
            premiumSubscription: "Premium Abonelik",
            subscriptionDescription: "3 ay boyunca tüm avantajlar ve teknik destek",
            per3Months: "/3 ay",
            subscriptionFeatures: [
                "Tüm özelliklere sınırsız erişim",
                "Öncelikli müşteri desteği",
                "Özel eğitim oturumları",
                "Aylık performans raporları"
            ],
            subscribeNow: "Şimdi Abone Ol",
            whatBarbersSay: "Berberlerimiz Ne Diyor",
            testimonial1Name: "Ahmet Yılmaz",
            testimonial1Quote: "Bu uygulama randevu takvimimizi devrim niteliğinde değiştirdi! Her gün zaman kazanıyoruz ve müşterilerimiz kolay çevrimiçi rezervasyonu seviyor.",
            testimonial1Location: "Keskin Makas Berber Salonu, Şehir Merkezi",
            testimonial2Name: "Ayşe Kaya",
            testimonial2Quote: "Uygulama sayesinde müşterilerimize daha fazla odaklanabiliyoruz. Yönetim özellikleri gerçekten harika!",
            testimonial2Location: "Saç Sanat Salonu, Üst Mahalle",
            testimonial3Name: "Mehmet Demir",
            testimonial3Quote: "Uygulamayı kullanmaya başladığımızdan beri gelirimiz %30 arttı. Hatırlatma özelliği, randevuya gelmeme durumunu büyük ölçüde azalttı!",
            testimonial3Location: "Sakal Bakım Merkezi, Orta Mahalle",
            services: "Hizmetler",
            barbers: "Berberler",
            bookings: "Rezervasyonlar",
            subscriptions: "Abonelikler",
            giftCards: "Hediye Kartları",
            company: "Şirket",
            aboutUs: "Hakkımızda",
            contact: "İletişim",
            jobs: "İş İlanları",
            pressReleases: "Basın Bültenleri",
            legal: "Yasal",
            termsOfUse: "Kullanım Şartları",
            privacyPolicy: "Gizlilik Politikası",
            cookiePolicy: "Çerez Politikası"
        },
        ar: {
            welcome: "مرحبًا بكم في BarberBuddy",
            heroDescription: "اكتشف فن قص الشعر المثالي. احجز موعدك التالي مع أفضل الحلاقين بالقرب منك.",
            bookNow: "احجز الآن",
            ourFeatures: "ميزاتنا",
            readyForNewLook: "هل أنت مستعد لمظهرك الجديد؟",
            bookAndExperience: "احجز موعدك الآن وجرب الفرق مع BarberBuddy.",
            findBarber: "ابحث عن حلاق الآن",
            easyBooking: "حجز سهل",
            easyBookingDesc: "احجز موعدك بنقرات قليلة، في أي وقت وأي مكان.",
            bestBarbers: "أفضل الحلاقين",
            bestBarbersDesc: "نحن نعمل مع أفضل الحلاقين في مدينتك فقط.",
            customerReviews: "آراء العملاء",
            customerReviewsDesc: "اقرأ آراء حقيقية من عملاء آخرين قبل الحجز.",
            customStyles: "أنماط مخصصة",
            customStylesDesc: "اختر من بين مجموعة متنوعة من قصات الشعر وخيارات العناية باللحية.",
            flexibleAppointments: "مواعيد مرنة",
            flexibleAppointmentsDesc: "اعثر على وقت الموعد المثالي الذي يناسب جدولك المزدحم.",
            loyaltyProgram: "برنامج الولاء",
            loyaltyProgramDesc: "اكسب نقاطًا مع كل حجز واستمتع بمزايا حصرية.",
            ourSubscriptions: "اشتراكاتنا",
            forBarbers: "للحلاقين فقط",
            premiumSubscription: "الاشتراك المميز",
            subscriptionDescription: "جميع المزايا والدعم الفني لمدة 3 أشهر",
            per3Months: "/3 أشهر",
            subscriptionFeatures: [
                "وصول غير محدود إلى جميع الميزات",
                "دعم العملاء ذو الأولوية",
                "جلسات تدريبية حصرية",
                "تقارير أداء شهرية"
            ],
            subscribeNow: "اشترك الآن",
            whatBarbersSay: "ماذا يقول حلاقونا",
            testimonial1Name: "محمد أحمد",
            testimonial1Quote: "لقد أحدث هذا التطبيق ثورة في تقويم المواعيد لدينا! نوفر الوقت يوميًا ويحب عملاؤنا الحجز عبر الإنترنت السهل.",
            testimonial1Location: "صالون قص وتهذيب، وسط المدينة",
            testimonial2Name: "فاطمة علي",
            testimonial2Quote: "بفضل التطبيق، يمكننا التركيز أكثر على عملائنا. ميزات الإدارة رائعة ببساطة!",
            testimonial2Location: "صالون الشعر الحاد، الحي الراقي",
            testimonial3Name: "أحمد محمود",
            testimonial3Quote: "ارتفعت إيراداتنا بنسبة 30٪ منذ أن بدأنا في استخدام التطبيق. لقد قللت ميزة التذكير بشكل كبير من حالات عدم الحضور!",
            testimonial3Location: "مركز العناية باللحية الفاخر، وسط المدينة",
            services: "الخدمات",
            barbers: "الحلاقون",
            bookings: "الحجوزات",
            subscriptions: "الاشتراكات",
            giftCards: "بطاقات الهدايا",
            company: "الشركة",
            aboutUs: "من نحن",
            contact: "اتصل بنا",
            jobs: "الوظائف",
            pressReleases: "البيانات الصحفية",
            legal: "قانوني",
            termsOfUse: "شروط الاستخدام",
            privacyPolicy: "سياسة الخصوصية",
            cookiePolicy: "سياسة ملفات تعريف الارتباط"
        },
        de: {
            welcome: "Willkommen bei BarberBuddy",
            heroDescription: "Entdecken Sie die Kunst des perfekten Haarschnitts. Buchen Sie Ihren nächsten Termin bei den besten Friseuren in Ihrer Nähe.",
            bookNow: "Jetzt buchen",
            ourFeatures: "Unsere Funktionen",
            readyForNewLook: "Bereit für einen neuen Look?",
            bookAndExperience: "Buchen Sie jetzt und erleben Sie den Unterschied mit BarberBuddy.",
            findBarber: "Finden Sie jetzt einen Friseur",
            easyBooking: "Einfache Buchung",
            easyBookingDesc: "Buchen Sie Ihren Termin mit wenigen Klicks, jederzeit und überall.",
            bestBarbers: "Die besten Friseure",
            bestBarbersDesc: "Wir arbeiten nur mit den besten Friseuren in Ihrer Stadt zusammen.",
            customerReviews: "Kundenbewertungen",
            customerReviewsDesc: "Lesen Sie echte Bewertungen von anderen Kunden vor der Buchung.",
            customStyles: "Individuelle Styles",
            customStylesDesc: "Wählen Sie aus einer Vielzahl von Haarschnitten und Bartpflege-Optionen.",
            flexibleAppointments: "Flexible Termine",
            flexibleAppointmentsDesc: "Finden Sie die perfekte Terminzeit, die zu Ihrem vollen Zeitplan passt.",
            loyaltyProgram: "Treueprogramm",
            loyaltyProgramDesc: "Sammeln Sie Punkte bei jeder Buchung und genießen Sie exklusive Vorteile.",
            ourSubscriptions: "Unsere Abonnements",
            forBarbers: "Nur für Friseure",
            premiumSubscription: "Premium-Abonnement",
            subscriptionDescription: "Alle Funktionen und technischer Support für 3 Monate",
            per3Months: "/3 Monate",
            subscriptionFeatures: [
                "Unbegrenzter Zugang zu allen Funktionen",
                "Prioritäts-Kundensupport",
                "Exklusive Schulungen",
                "Monatliche Leistungsberichte"
            ],
            subscribeNow: "Jetzt Abonnieren",
            whatBarbersSay: "Was unsere Barbiere sagen",
            testimonial1Name: "Hans Müller",
            testimonial1Quote: "Diese App hat unseren Terminkalender revolutioniert! Wir sparen täglich Zeit und unsere Kunden lieben die einfache Online-Buchung.",
            testimonial1Location: "Schnipp Schnapp Barbershop, Innenstadt",
            testimonial2Name: "Lisa Schmidt",
            testimonial2Quote: "Dank der App können wir uns mehr auf unsere Kunden konzentrieren. Die Verwaltungsfunktionen sind einfach großartig!",
            testimonial2Location: "Haarscharf Salon, Stadtrand",
            testimonial3Name: "Max Weber",
            testimonial3Quote: "Unser Umsatz ist um 30% gestiegen, seit wir die App nutzen. Die Erinnerungsfunktion hat No-Shows drastisch reduziert!",
            testimonial3Location: "Bartpflege Deluxe, Stadtmitte",
            services: "Dienstleistungen",
            barbers: "Barbiere",
            bookings: "Buchungen",
            subscriptions: "Abonnements",
            giftCards: "Geschenkgutscheine",
            company: "Unternehmen",
            aboutUs: "Über uns",
            contact: "Kontakt",
            jobs: "Jobs",
            pressReleases: "Pressemitteilungen",
            legal: "Rechtliches",
            termsOfUse: "Nutzungsbedingungen",
            privacyPolicy: "Datenschutzrichtlinie",
            cookiePolicy: "Cookie-Richtlinie"
        }
    };

    const t = translations[language];

    return (
        <div className="flex flex-col min-h-screen">
            {auth.currentUser && <TrialStatus/>}
            <section className="hero min-h-screen bg-base-200 relative">
                <div className="hero-background absolute inset-0 bg-cover bg-center bg-no-repeat"></div>
                <div className="absolute inset-0 bg-black bg-opacity-50"></div>
                <div className="hero-content text-center relative z-10">
                    <div className="max-w-md">
                        <h1 className="text-5xl font-bold text-white">{t.welcome}</h1>
                        <p className="py-6 text-white">{t.heroDescription}</p>
                        <Link to="/shops" className="btn btn-primary">{t.bookNow}</Link>
                    </div>
                </div>
            </section>

            <section className="py-20 bg-base-100">
                <div className="container mx-auto px-4">
                    <h2 className="text-3xl font-bold text-center mb-12 text-primary">{t.ourFeatures}</h2>
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                        <FunctionCard title={t.easyBooking} description={t.easyBookingDesc} icon="📅"/>
                        <FunctionCard title={t.bestBarbers} description={t.bestBarbersDesc} icon="✂️"/>
                        <FunctionCard title={t.customerReviews} description={t.customerReviewsDesc} icon="⭐"/>
                        <FunctionCard title={t.customStyles} description={t.customStylesDesc} icon="💇‍♂️"/>
                        <FunctionCard title={t.flexibleAppointments} description={t.flexibleAppointmentsDesc}
                                      icon="🕰️"/>
                        <FunctionCard title={t.loyaltyProgram} description={t.loyaltyProgramDesc} icon="🎁"/>
                    </div>
                </div>
            </section>

            <section className="py-20 bg-base-200">
                <div className="container mx-auto px-4">
                    <h2 className="text-3xl font-bold text-center mb-12 text-primary">{t.ourSubscriptions}</h2>
                    <div className="flex justify-center">
                        <div className="w-full max-w-md">
                            <SubscriptionCard t={t}/>
                        </div>
                    </div>
                </div>
            </section>

            <section className="py-20 bg-base-100">
                <div className="container mx-auto px-4">
                    <h2 className="text-3xl font-bold text-center mb-12 text-primary">{t.whatBarbersSay}</h2>
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                        <TestimonialCard
                            name={t.testimonial1Name}
                            quote={t.testimonial1Quote}
                            image="https://cdn.jsdelivr.net/gh/alohe/memojis/png/vibrent_24.png"
                            location={t.testimonial1Location}
                        />
                        <TestimonialCard
                            name={t.testimonial2Name}
                            quote={t.testimonial2Quote}
                            image="https://cdn.jsdelivr.net/gh/alohe/memojis/png/memo_17.png"
                            location={t.testimonial2Location}
                        />
                        <TestimonialCard
                            name={t.testimonial3Name}
                            quote={t.testimonial3Quote}
                            image="https://cdn.jsdelivr.net/gh/alohe/memojis/png/vibrent_11.png"
                            location={t.testimonial3Location}
                        />
                    </div>
                </div>
            </section>

            <section className="py-20 bg-base-200 text-primary-content">
                <div className="container mx-auto px-4 text-center">
                    <h2 className="text-3xl font-bold mb-4">{t.readyForNewLook}</h2>
                    <p className="mb-8">{t.bookAndExperience}</p>
                    <Link to="/shops" className="btn btn-secondary btn-lg">{t.findBarber}</Link>
                </div>
            </section>

            <footer className="footer p-10 bg-neutral text-neutral-content">
                <div>
                    <span className="footer-title">{t.services}</span>
                    <a className="link link-hover pointer-events-none opacity-50">{t.barbers}</a>
                    <a className="link link-hover pointer-events-none opacity-50">{t.bookings}</a>
                    <a className="link link-hover pointer-events-none opacity-50">{t.subscriptions}</a>
                    <a className="link link-hover pointer-events-none opacity-50">{t.giftCards}</a>
                </div>
                <div>
                    <span className="footer-title">{t.company}</span>
                    <a className="link link-hover pointer-events-none opacity-50">{t.aboutUs}</a>
                    <a className="link link-hover pointer-events-none opacity-50">{t.contact}</a>
                    <a className="link link-hover pointer-events-none opacity-50">{t.jobs}</a>
                    <a className="link link-hover pointer-events-none opacity-50">{t.pressReleases}</a>
                </div>
                <div>
                    <span className="footer-title">{t.legal}</span>
                    <a className="link link-hover pointer-events-none opacity-50">{t.termsOfUse}</a>
                    <a className="link link-hover pointer-events-none opacity-50">{t.privacyPolicy}</a>
                    <a className="link link-hover pointer-events-none opacity-50">{t.cookiePolicy}</a>
                </div>
            </footer>
        </div>
    );
};

const FunctionCard = ({title, description, icon}) => (
    <div className="card bg-base-100 shadow-xl">
        <div className="card-body items-center text-center">
            <div className="text-4xl mb-4">{icon}</div>
            <h3 className="card-title text-primary">{title}</h3>
            <p>{description}</p>
        </div>
    </div>
);

const SubscriptionCard = ({t}) => (
    <div className="bg-white rounded-xl shadow-md overflow-hidden">
        <div className="p-8 w-full">
            <div className="uppercase tracking-wide text-sm text-indigo-500 font-semibold">{t.forBarbers}</div>
            <h2 className="block mt-1 text-lg leading-tight font-medium text-black">{t.premiumSubscription}</h2>
            <p className="mt-2 text-gray-500">{t.subscriptionDescription}</p>
            <div className="mt-4">
                <span className="text-4xl font-bold">50€</span>
                <span className="text-gray-500 ml-1">{t.per3Months}</span>
            </div>
            <ul className="mt-6 space-y-4">
                {t.subscriptionFeatures && t.subscriptionFeatures.map((feature, index) => (
                    <li key={index} className="flex items-center">
                        <svg className="h-5 w-5 text-green-500 mr-2" fill="none" strokeLinecap="round"
                             strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" stroke="currentColor">
                            <path d="M5 13l4 4L19 7"></path>
                        </svg>
                        {feature}
                    </li>
                ))}
            </ul>
            <div className="mt-8">
                <button
                    className="w-full bg-indigo-600 text-white py-2 px-4 rounded-lg hover:bg-indigo-700 transition duration-300">
                    {t.subscribeNow}
                </button>
            </div>
        </div>
    </div>
);

const TestimonialCard = ({name, quote, image, location}) => (
    <div className="card bg-base-100 shadow-xl">
        <figure className="px-10 pt-10">
            <img src={image} alt={name} className="rounded-xl w-24 h-24 object-cover"/>
        </figure>
        <div className="card-body items-center text-center">
            <p className="text-sm italic">"{quote}"</p>
            <p className="font-bold mt-2">- {name}</p>
            <p className="text-xs text-gray-500">{location}</p>
        </div>
    </div>
);

export default Home;