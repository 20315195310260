import React from 'react';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import Navbar from './components/Navbar';
import Home from './components/Home';
import Shops from './components/BarberShops';
import Auth from './components/Auth';
import CreateBar from './components/CreateBarberShop';
import AccountPage from "./components/Account";
import ShopLandingPage from "./components/ShopLandingPage";
import useStore from './store';
import BookNow from "./components/BookNow";
import SubscriptionPage from "./components/SubscriptionForm";
import {loadStripe} from "@stripe/stripe-js";
import {Elements} from "@stripe/react-stripe-js";
import {LanguageProvider} from "./components/LanguageContext";

const stripePromise = loadStripe('pk_test_51PZTr0Rw74eQDMkWnd1fIXoCsYde59onlv6XZudfDsboYAeVWzmXZpgpAvwLRhT6eMcL30kDmLskxsCAsnQGXaQ600NmqA38ln');

function App() {
    const {theme} = useStore();

    return (
        <LanguageProvider>
            <Router>
                <Elements stripe={stripePromise}>
                    <div data-theme="barber" className="min-h-screen bg-base-100">
                        <Navbar/>
                        <Routes>
                            <Route path="/" element={<Home/>}/>
                            <Route path="/auth" element={<Auth/>}/>
                            <Route path="/shops" element={<Shops/>}/>
                            <Route path="/account" element={<AccountPage/>}/>
                            <Route path="/create-shop" element={<CreateBar/>}/>
                            <Route path="/shop/:uniqueUrl" element={<ShopLandingPage/>}/>
                            <Route path="/book/:shopId" element={<BookNow/>}/>
                            <Route path="/subscribe" element={<SubscriptionPage/>}/>
                        </Routes>
                    </div>
                </Elements>
            </Router>
        </LanguageProvider>
    );
}

export default App;