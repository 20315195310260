import React, { useContext, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import useStore from '../store';
import { auth } from '../firebase';
import { onAuthStateChanged, signOut } from 'firebase/auth';
import LanguageContext, { useLanguage } from './LanguageContext';

const Navbar = () => {
    const { theme, toggleTheme } = useStore();
    const [user, setUser] = useState(null);
    const navigate = useNavigate();
    const { language, changeLanguage } = useContext(LanguageContext);

    useEffect(() => {
        document.documentElement.setAttribute('data-theme', theme);
    }, [theme]);

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
            setUser(currentUser);
        });

        return () => unsubscribe();
    }, []);

    const handleSignOut = async () => {
        try {
            await signOut(auth);
            navigate('/');
        } catch (error) {
            console.error('Error signing out:', error);
        }
    };

    const languages = {
        tr: { name: '', flag: 'TR' },
        en: { name: '', flag: 'GB' },
        ar: { name: '', flag: 'AR' },
        de: { name: '', flag: 'DE' }
    };

    const translations = {
        en: {
            findBarber: "Find a Barber",
            createBarberShop: "Create Barber Shop",
            myAccount: "My Account",
            darkMode: "Dark Mode 🌙",
            lightMode: "Light Mode ☀️",
            signOut: "Sign Out",
            signIn: "Sign In"
        },
        tr: {
            findBarber: "Berber Bul",
            createBarberShop: "Berber Dükkanı Oluştur",
            myAccount: "Hesabım",
            darkMode: "Karanlık Mod 🌙",
            lightMode: "Aydınlık Mod ☀️",
            signOut: "Çıkış Yap",
            signIn: "Giriş Yap"
        },
        ar: {
            findBarber: "ابحث عن حلاق",
            createBarberShop: "إنشاء صالون حلاقة",
            myAccount: "حسابي",
            darkMode: "الوضع المظلم 🌙",
            lightMode: "الوضع المضيء ☀️",
            signOut: "تسجيل الخروج",
            signIn: "تسجيل الدخول"
        },
        de: {
            findBarber: "Friseur finden",
            createBarberShop: "Friseursalon erstellen",
            myAccount: "Mein Konto",
            darkMode: "Dunkelmodus 🌙",
            lightMode: "Hellmodus ☀️",
            signOut: "Abmelden",
            signIn: "Anmelden"
        }
    };

    const t = translations[language];

    return (
        <div className="navbar bg-base-100">
            <div className="flex-1">
                <Link to="/" className="btn btn-ghost text-xl">BarberBuddy</Link>
            </div>
            <div className="flex-none gap-2">
                <Link to="/shops" className="btn btn-ghost btn-sm rounded-btn">
                    {t.findBarber}
                </Link>
                {user && (
                    <Link to="/create-shop" className="btn btn-primary btn-sm rounded-btn">
                        {t.createBarberShop}
                    </Link>
                )}
                <div className="dropdown dropdown-end">
                    <div tabIndex={0} role="button" className="btn btn-ghost btn-sm rounded-btn">
                        {languages[language].flag} {languages[language].name}
                    </div>
                    <ul tabIndex={0} className="menu dropdown-content z-[1] p-2 shadow bg-base-100 rounded-box w-52 mt-4">
                        {Object.entries(languages).map(([code, { name, flag }]) => (
                            <li key={code}>
                                <button onClick={() => changeLanguage(code)}>
                                    {flag} {name}
                                </button>
                            </li>
                        ))}
                    </ul>
                </div>
                {user ? (
                    <div className="dropdown dropdown-end">
                        <div tabIndex={0} role="button" className="btn btn-ghost btn-circle avatar">
                            <div className="w-10 rounded-full">
                                <img
                                    alt="User avatar"
                                    src={user.photoURL || "https://img.daisyui.com/images/stock/photo-1534528741775-53994a69daeb.jpg"} />
                            </div>
                        </div>
                        <ul
                            tabIndex={0}
                            className="menu menu-sm dropdown-content bg-base-100 rounded-box z-[1] mt-3 w-52 p-2 shadow">
                            <li>
                                <Link to="/account" className="justify-between">
                                    {t.myAccount}
                                    <span className="badge">New</span>
                                </Link>
                            </li>
                            <li>
                                <button onClick={toggleTheme}>
                                    {theme === 'emerald' ? t.darkMode : t.lightMode}
                                </button>
                            </li>
                            <li>
                                <button onClick={handleSignOut}>{t.signOut}</button>
                            </li>
                        </ul>
                    </div>
                ) : (
                    <>
                        <Link to="/auth" className="btn btn-ghost btn-sm rounded-btn">
                            {t.signIn}
                        </Link>
                        <button onClick={toggleTheme} className="btn btn-ghost btn-sm rounded-btn">
                            {theme === 'emerald' ? '🌙' : '☀️'}
                        </button>
                    </>
                )}
            </div>
        </div>
    );
};

export default Navbar;