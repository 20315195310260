import {initializeApp} from 'firebase/app';
import {getAuth, GoogleAuthProvider, signInWithPopup} from 'firebase/auth';
import {getStorage} from 'firebase/storage';
import {getFirestore, doc, getDoc, updateDoc} from 'firebase/firestore';

const firebaseConfig = {
    apiKey: "AIzaSyC7Z9m_nc0qaTRg_8uXeD6vl9OVQ6rlFpY",
    authDomain: "easycut-2d3fa.firebaseapp.com",
    projectId: "easycut-2d3fa",
    storageBucket: "easycut-2d3fa.appspot.com",
    messagingSenderId: "843132448233",
    appId: "1:843132448233:web:9fbd06913cb0ae594b2f78",
    measurementId: "G-HQP95EQ1VZ"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);
const googleProvider = new GoogleAuthProvider();

const signInWithGoogle = async () => {
    console.log("Initiating Google Sign-In");
    try {
        const result = await signInWithPopup(auth, googleProvider);
        console.log("Google Sign-In successful:", result.user);
        return result.user;
    } catch (error) {
        console.error("Error during Google Sign-In:", error);
        throw error;
    }
};

export const getUserData = async (userId) => {
    const userRef = doc(db, 'users', userId);
    const userSnap = await getDoc(userRef);
    if (userSnap.exists()) {
        return userSnap.data();
    } else {
        throw new Error('User not found');
    }
};

export const updateUserSubscription = async (userId, subscriptionData) => {
    const userRef = doc(db, 'users', userId);
    await updateDoc(userRef, subscriptionData);
};

export {auth, db, storage, signInWithGoogle};