import React, { useState, useEffect, useContext } from 'react';
import { auth, db, storage } from '../firebase';
import { collection, query, where, getDocs, deleteDoc, doc, updateDoc } from 'firebase/firestore';
import { ref, getDownloadURL, deleteObject } from 'firebase/storage';
import { useNavigate } from 'react-router-dom';
import useStore from '../store';
import Swal from 'sweetalert2';
import LanguageContext from "./LanguageContext";


const translations = {
    en: {
        loading: "Loading...",
        pleaseLogIn: "Please log in to view your account.",
        myAccount: "My Account",
        accountInfo: "Account Information",
        name: "Name:",
        email: "Email:",
        myBarberShops: "My Barber Shops",
        address: "Address:",
        services: "Services",
        availability: "Availability",
        shopImages: "Shop Images",
        uniqueShopLink: "Unique Shop Link",
        deleteShop: "Delete Shop",
        noShops: "You haven't created any barber shops yet.",
        createShop: "Create Barber Shop",
        copied: "Copied!",
        copiedText: "The shop link has been copied to your clipboard.",
        areYouSure: "Are you sure?",
        cantRevert: "You won't be able to revert this!",
        yesDelete: "Yes, delete it!",
        success: "Success",
        shopDeleted: "Your barber shop has been deleted.",
        error: "Error",
        errorDeleting: "There was an error deleting your barber shop. Please try again."
    },
    tr: {
        loading: "Yükleniyor...",
        pleaseLogIn: "Hesabınızı görüntülemek için lütfen giriş yapın.",
        myAccount: "Hesabım",
        accountInfo: "Hesap Bilgileri",
        name: "İsim:",
        email: "E-posta:",
        myBarberShops: "Berber Dükkanlarım",
        address: "Adres:",
        services: "Hizmetler",
        availability: "Müsaitlik",
        shopImages: "Dükkan Görselleri",
        uniqueShopLink: "Benzersiz Dükkan Linki",
        deleteShop: "Dükkanı Sil",
        noShops: "Henüz bir berber dükkanı oluşturmadınız.",
        createShop: "Berber Dükkanı Oluştur",
        copied: "Kopyalandı!",
        copiedText: "Dükkan linki panonuza kopyalandı.",
        areYouSure: "Emin misiniz?",
        cantRevert: "Bu işlemi geri alamazsınız!",
        yesDelete: "Evet, sil!",
        success: "Başarılı",
        shopDeleted: "Berber dükkanınız silindi.",
        error: "Hata",
        errorDeleting: "Berber dükkanınızı silerken bir hata oluştu. Lütfen tekrar deneyin."
    },
    ar: {
        loading: "جاري التحميل...",
        pleaseLogIn: "الرجاء تسجيل الدخول لعرض حسابك.",
        myAccount: "حسابي",
        accountInfo: "معلومات الحساب",
        name: "الاسم:",
        email: "البريد الإلكتروني:",
        myBarberShops: "محلات الحلاقة الخاصة بي",
        address: "العنوان:",
        services: "الخدمات",
        availability: "التوفر",
        shopImages: "صور المحل",
        uniqueShopLink: "رابط المحل الفريد",
        deleteShop: "حذف المحل",
        noShops: "لم تقم بإنشاء أي محل حلاقة بعد.",
        createShop: "إنشاء محل حلاقة",
        copied: "تم النسخ!",
        copiedText: "تم نسخ رابط المحل إلى الحافظة.",
        areYouSure: "هل أنت متأكد؟",
        cantRevert: "لن تتمكن من التراجع عن هذا!",
        yesDelete: "نعم، احذفه!",
        success: "نجاح",
        shopDeleted: "تم حذف محل الحلاقة الخاص بك.",
        error: "خطأ",
        errorDeleting: "حدث خطأ أثناء حذف محل الحلاقة الخاص بك. يرجى المحاولة مرة أخرى."
    },
    de: {
        loading: "Laden...",
        pleaseLogIn: "Bitte melden Sie sich an, um Ihr Konto anzuzeigen.",
        myAccount: "Mein Konto",
        accountInfo: "Kontoinformationen",
        name: "Name:",
        email: "E-Mail:",
        myBarberShops: "Meine Friseursalons",
        address: "Adresse:",
        services: "Dienstleistungen",
        availability: "Verfügbarkeit",
        shopImages: "Salonbilder",
        uniqueShopLink: "Eindeutiger Salon-Link",
        deleteShop: "Salon löschen",
        noShops: "Sie haben noch keine Friseursalons erstellt.",
        createShop: "Friseursalon erstellen",
        copied: "Kopiert!",
        copiedText: "Der Salon-Link wurde in Ihre Zwischenablage kopiert.",
        areYouSure: "Sind Sie sicher?",
        cantRevert: "Sie können dies nicht rückgängig machen!",
        yesDelete: "Ja, löschen!",
        success: "Erfolg",
        shopDeleted: "Ihr Friseursalon wurde gelöscht.",
        error: "Fehler",
        errorDeleting: "Beim Löschen Ihres Friseursalons ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut."
    }
};

const AccountPage = () => {
    const { language } = useContext(LanguageContext);
    const t = translations[language];
    const [user, setUser] = useState(null);
    const [shops, setShops] = useState([]);
    const [loading, setLoading] = useState(true);
    const [editMode, setEditMode] = useState(false);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const navigate = useNavigate();
    const { setUserShops, removeUserShop } = useStore();

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged(async (currentUser) => {
            if (currentUser) {
                setUser(currentUser);
                setName(currentUser.displayName || '');
                setEmail(currentUser.email || '');
                setPhone(currentUser.phoneNumber || '');
                await fetchShopData(currentUser.uid);
            } else {
                navigate('/auth');
            }
            setLoading(false);
        });

        return () => unsubscribe();
    }, [navigate]);

    const formatAvailability = (availability) => {
        if (!availability || typeof availability !== 'object') {
            return 'No availability information';
        }

        const formattedAvailability = Object.entries(availability)
            .filter(([_, hours]) => hours !== null && typeof hours === 'object' && hours.open && hours.close)
            .map(([day, hours]) => `${day}: ${hours.open} - ${hours.close}`);

        return formattedAvailability.length > 0
            ? formattedAvailability.join(', ')
            : 'No available hours set';
    };

    const copyToClipboard = (text) => {
        navigator.clipboard.writeText(text).then(() => {
            Swal.fire({
                title: t.copied,
                text: t.copiedText,
                icon: 'success',
                timer: 2000,
                showConfirmButton: false
            });
        }, (err) => {
            console.error('Could not copy text: ', err);
        });
    };

    const deleteShop = async (shopId, imageUrls) => {
        try {
            await deleteDoc(doc(db, 'barberShops', shopId));
            const deletePromises = imageUrls.map(url => {
                const imageRef = ref(storage, url);
                return deleteObject(imageRef);
            });
            await Promise.all(deletePromises);
            setShops(shops.filter(shop => shop.id !== shopId));
            removeUserShop(shopId);
            Swal.fire({
                title: t.success,
                text: t.shopDeleted,
                icon: 'success',
                confirmButtonText: 'OK'
            });
        } catch (error) {
            console.error('Error deleting shop:', error);
            Swal.fire({
                title: t.error,
                text: t.errorDeleting,
                icon: 'error',
                confirmButtonText: 'OK'
            });
        }
    };

    const handleDeleteShop = (shop) => {
        Swal.fire({
            title: t.areYouSure,
            text: t.cantRevert,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d81046',
            cancelButtonColor: '#168dd8',
            confirmButtonText: t.yesDelete
        }).then((result) => {
            if (result.isConfirmed) {
                deleteShop(shop.id, shop.imageUrls);
            }
        });
    };

    const fetchShopData = async (userId) => {
        try {
            const q = query(collection(db, 'barberShops'), where('ownerId', '==', userId));
            const querySnapshot = await getDocs(q);
            const shopList = [];
            for (const doc of querySnapshot.docs) {
                const shopData = doc.data();
                const imageUrls = await Promise.all(
                    shopData.imageUrls.map(async (imageRef) => {
                        try {
                            return await getDownloadURL(ref(storage, imageRef));
                        } catch (error) {
                            console.error('Error fetching image URL:', error);
                            return null;
                        }
                    })
                );
                shopList.push({id: doc.id, ...shopData, imageUrls: imageUrls.filter(url => url !== null)});
            }
            setShops(shopList);
            setUserShops(shopList);
        } catch (error) {
            console.error('Error fetching shop data:', error);
        }
    };

    const handleSaveProfile = async () => {
        try {
            await auth.currentUser.updateProfile({ displayName: name });
            await auth.currentUser.updateEmail(email);
            if (phone) {
                await updateDoc(doc(db, 'users', auth.currentUser.uid), {
                    phoneNumber: phone
                });
            }
            setEditMode(false);
            setUser({ ...auth.currentUser, phoneNumber: phone });
            Swal.fire({
                title: t.success,
                text: t.profileUpdated,
                icon: 'success',
                confirmButtonText: 'OK'
            });
        } catch (error) {
            console.error('Error updating profile:', error);
            Swal.fire({
                title: t.error,
                text: t.errorUpdatingProfile,
                icon: 'error',
                confirmButtonText: 'OK'
            });
        }
    };

    const handleEditShop = (shopId) => {
        console.log('Edit shop:', shopId);
        Swal.fire({
            title: t.comingSoon,
            text: t.shopEditingFunctionality,
            icon: 'info',
            confirmButtonText: 'OK'
        });
    };

    if (loading) {
        return <div className="text-center py-4">{t.loading}</div>;
    }

    if (!user) {
        return <div className="text-center py-4">{t.pleaseLogIn}</div>;
    }

    //
    // const deleteShop = async (shopId, imageUrls) => {
    //     try {
    //         // Delete the shop document from Firestore
    //         await deleteDoc(doc(db, 'barberShops', shopId));
    //
    //         // Delete all images from Storage
    //         const deletePromises = imageUrls.map(url => {
    //             const imageRef = ref(storage, url);
    //             return deleteObject(imageRef);
    //         });
    //         await Promise.all(deletePromises);
    //
    //         // Update local state
    //         setShops(shops.filter(shop => shop.id !== shopId));
    //         removeUserShop(shopId);
    //
    //         Swal.fire({
    //             title: 'Success',
    //             text: 'Your barber shop has been deleted.',
    //             icon: 'success',
    //             confirmButtonText: 'OK'
    //         });
    //     } catch (error) {
    //         console.error('Error deleting shop:', error);
    //         Swal.fire({
    //             title: 'Error',
    //             text: 'There was an error deleting your barber shop. Please try again.',
    //             icon: 'error',
    //             confirmButtonText: 'OK'
    //         });
    //     }
    // };
    //
    // const handleDeleteShop = (shop) => {
    //     Swal.fire({
    //         title: 'Are you sure?',
    //         text: "You won't be able to revert this!",
    //         icon: 'warning',
    //         showCancelButton: true,
    //         confirmButtonColor: '#d81046',
    //         cancelButtonColor: '#168dd8',
    //         confirmButtonText: 'Yes, delete it!'
    //     }).then((result) => {
    //         if (result.isConfirmed) {
    //             deleteShop(shop.id, shop.imageUrls);
    //         }
    //     });
    // };
    //
    // const fetchShopData = async (userId) => {
    //     try {
    //         const q = query(collection(db, 'barberShops'), where('ownerId', '==', userId));
    //         const querySnapshot = await getDocs(q);
    //         const shopList = [];
    //         for (const doc of querySnapshot.docs) {
    //             const shopData = doc.data();
    //             // Fetch image URLs
    //             const imageUrls = await Promise.all(
    //                 shopData.imageUrls.map(async (imageRef) => {
    //                     try {
    //                         return await getDownloadURL(ref(storage, imageRef));
    //                     } catch (error) {
    //                         console.error('Error fetching image URL:', error);
    //                         return null;
    //                     }
    //                 })
    //             );
    //             shopList.push({id: doc.id, ...shopData, imageUrls: imageUrls.filter(url => url !== null)});
    //         }
    //         setShops(shopList);
    //         setUserShops(shopList);
    //     } catch (error) {
    //         console.error('Error fetching shop data:', error);
    //     }
    // };
    //
    // if (loading) {
    //     return <div className="text-center py-4">Loading...</div>;
    // }
    //
    // if (!user) {
    //     return <div className="text-center py-4">Please log in to view your account.</div>;
    // }

    return (
        <div className="container mx-auto px-4 py-8">
            <h1 className="text-3xl font-bold mb-6">{t.myAccount}</h1>
            <div className="bg-base-200 p-6 rounded-lg shadow-lg">
                <h2 className="text-2xl font-semibold mb-4">{t.accountInfo}</h2>
                {editMode ? (
                    <>
                        <div className="mb-4">
                            <label className="block mb-2" htmlFor="name">{t.name}</label>
                            <input
                                type="text"
                                id="name"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                className="w-full p-2 border rounded"
                            />
                        </div>
                        <div className="mb-4">
                            <label className="block mb-2" htmlFor="email">{t.email}</label>
                            <input
                                type="email"
                                id="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                className="w-full p-2 border rounded"
                            />
                        </div>
                        <div className="mb-4">
                            <label className="block mb-2" htmlFor="phone">{t.phone}</label>
                            <input
                                type="tel"
                                id="phone"
                                value={phone}
                                onChange={(e) => setPhone(e.target.value)}
                                className="w-full p-2 border rounded"
                            />
                        </div>
                        <button onClick={handleSaveProfile} className="btn btn-primary mr-2">
                            {t.saveChanges} Save Changes
                        </button>
                        <button onClick={() => setEditMode(false)} className="btn btn-secondary">
                            {t.cancel} Cancel
                        </button>
                    </>
                ) : (
                    <>
                        <p><strong>{t.name}</strong> {user.displayName}</p>
                        <p><strong>{t.email}</strong> {user.email}</p>
                        {user.phoneNumber && <p><strong>{t.phone}</strong> {user.phoneNumber}</p>}
                        <button onClick={() => setEditMode(true)} className="btn btn-primary mt-4">
                            {t.editProfile} Update Account
                        </button>
                    </>
                )}
            </div>

            <h2 className="text-2xl font-semibold mt-8 mb-4">{t.myBarberShops}</h2>
            {shops.length > 0 ? (
                shops.map((shop) => (
                    <div key={shop.id} className="mt-4 bg-base-200 p-6 rounded-lg shadow-lg">
                        <h3 className="text-xl font-semibold mb-2">{shop.name}</h3>
                        <p><strong>{t.address}</strong> {shop.address}</p>
                        <div className="mt-4">
                            <h4 className="text-lg font-semibold mb-2">{t.services}</h4>
                            <ul className="list-disc list-inside">
                                {shop.services.map((service, index) => (
                                    <li key={index}>{service.name} - €{service.price}</li>
                                ))}
                            </ul>
                            <h4 className="text-lg font-semibold mt-4 mb-2">{t.availability}</h4>
                            <p>{formatAvailability(shop.availability)}</p>
                        </div>
                        <div className="mt-4">
                            <h4 className="text-lg font-semibold mb-2">{t.shopImages}</h4>
                            <div className="grid grid-cols-2 md:grid-cols-3 gap-4">
                                {shop.imageUrls.map((url, index) => (
                                    <img key={index} src={url} alt={`Shop image ${index + 1}`}
                                         className="w-full h-40 object-cover rounded"/>
                                ))}
                            </div>
                        </div>
                        <div className="mt-4">
                            <h4 className="text-lg font-semibold mb-2">{t.uniqueShopLink}</h4>
                            <div
                                className="cursor-pointer text-blue-500 hover:text-blue-700 underline"
                                onClick={() => copyToClipboard(`${window.location.origin}/shop/${shop.uniqueUrl}`)}
                            >
                                {`${window.location.origin}/shop/${shop.uniqueUrl}`}
                            </div>
                        </div>
                        <button
                            onClick={() => handleDeleteShop(shop)}
                            className="btn btn-error mt-4"
                        >
                            {t.deleteShop}
                        </button>
                    </div>
                ))
            ) : (
                <div className="mt-4 bg-base-200 p-6 rounded-lg shadow-lg">
                    <p>{t.noShops}</p>
                    <button
                        onClick={() => navigate('/create-shop')}
                        className="btn btn-primary mt-4"
                    >
                        {t.createShop}
                    </button>
                </div>
            )}
        </div>
    );
};

export default AccountPage;