import React, {useContext} from 'react';
import LanguageContext from "./LanguageContext";

const presetServices = [
    { name: 'Herrenhaarschnitt', price: '25' },
    { name: 'Maschinenhaarschnitt', price: '20' },
    { name: 'Bartpflege', price: '15' },
    { name: 'Rasur', price: '20' },
    { name: 'Kinderhaarschnitt', price: '18' },
    { name: 'Färben', price: '45' },
    { name: 'Strähnchen', price: '55' },
    { name: 'Waschen & Föhnen', price: '15' },
    { name: 'Styling', price: '10' },
    { name: 'Kopfmassage', price: '12' },
];

const PresetServiceSelector = ({ onServiceSelect }) => {
    const { language } = useContext(LanguageContext);

    const translations = {
        en: {
            menHaircut: "Men's Haircut",
            machineHaircut: "Machine Haircut",
            beardGrooming: "Beard Grooming",
            shave: "Shave",
            kidsHaircut: "Kids Haircut",
            coloring: "Coloring",
            highlights: "Highlights",
            washAndBlow: "Wash & Blow Dry",
            styling: "Styling",
            headMassage: "Head Massage"
        },
        tr: {
            menHaircut: "Erkek Saç Kesimi",
            machineHaircut: "Makine ile Saç Kesimi",
            beardGrooming: "Sakal Bakımı",
            shave: "Tıraş",
            kidsHaircut: "Çocuk Saç Kesimi",
            coloring: "Boyama",
            highlights: "Röfle",
            washAndBlow: "Yıkama ve Fön",
            styling: "Şekillendirme",
            headMassage: "Kafa Masajı"
        },
        ar: {
            menHaircut: "قص شعر رجالي",
            machineHaircut: "قص الشعر بالماكينة",
            beardGrooming: "تهذيب اللحية",
            shave: "حلاقة",
            kidsHaircut: "قص شعر الأطفال",
            coloring: "صبغ الشعر",
            highlights: "هايلايت",
            washAndBlow: "غسيل وتجفيف",
            styling: "تصفيف",
            headMassage: "تدليك الرأس"
        },
        de: {
            menHaircut: "Herrenhaarschnitt",
            machineHaircut: "Maschinenhaarschnitt",
            beardGrooming: "Bartpflege",
            shave: "Rasur",
            kidsHaircut: "Kinderhaarschnitt",
            coloring: "Färben",
            highlights: "Strähnchen",
            washAndBlow: "Waschen & Föhnen",
            styling: "Styling",
            headMassage: "Kopfmassage"
        }
    };

    const t = translations[language];

    return (
        <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-2 mb-4">
            {presetServices.map((service, index) => (
                <button
                    key={index}
                    type="button"
                    onClick={(e) => {
                        e.preventDefault();
                        onServiceSelect(service);
                    }}
                    className="btn btn-outline btn-lg normal-case text-left flex flex-col items-start justify-between h-fit"
                >
                    <span className="truncate">{t[Object.keys(t)[index]] || service.name}</span>
                    <span className="text-md opacity-70">€{service.price}</span>
                </button>
            ))}
        </div>
    );
};

export default PresetServiceSelector;